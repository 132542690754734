<template>
    <AuthFormContainer :comment="pageComment" :title="pageTitle" v-if="companyInvitation">
        <div class="signup-form">
            <UserInfoForm v-show="isStepUserInfo" :prefill-data="userInfo" :api-validation-errors="userValidationErrors"
                          submit-button-text="Next Step" @form-filled="updateUserInfo" @toggle-step="setCurrentStep"/>

            <CompanyInfoForm v-show="isStepCompanyInfo" :loading="loading" :prefill-data="companyInfo"
                             :api-validation-errors="companyValidationErrors" @form-filled="updateCompanyInfo"
                             @toggle-step="setCurrentStep"/>
        </div>
    </AuthFormContainer>
    <Expired v-else-if="getFieldValidationError('token') || getFieldValidationError('ui_token')"/>
</template>

<script>
import AuthFormContainer from "@/components/AuthFormContainer.vue";
import UserInfoForm from "@/components/Forms/SignUpForm/UserInfoForm.vue";
import CompanyInfoForm from "@/components/Forms/SignUpForm/CompanyInfoForm.vue";
import CompanyInvitations from "@/api/v2/endpoints/CompanyInvitations";
import Expired from "@/views/Error/Expired.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";

const STEP_USER_INFO = 'user-info';
const STEP_COMPANY_INFO = 'company-info';

export default {
    name: 'CompanySignUp',
    mixins: [apiErrorHandler],
    components: {
        CompanyInfoForm,
        UserInfoForm,
        AuthFormContainer,
        Expired
    },
    data() {
        return {
            token: null,
            userInvitationToken: null,
            loading: false,
            currentStep: STEP_USER_INFO,
            companyInvitation: null,
            companyValidationErrors: {},
            companyInfo: {},
            userValidationErrors: {},
            userInfo: {},
        }
    },
    computed: {
        isStepUserInfo() {
            return this.currentStep === STEP_USER_INFO;
        },
        isStepCompanyInfo() {
            return this.currentStep === STEP_COMPANY_INFO;
        },
        pageTitle() {
            if (this.isStepUserInfo)
                return 'Create Account - User Info'

            return 'Create Account - Company Info'
        },
        pageComment() {
            if (this.isStepUserInfo)
                return 'Your Profile'

            return 'Your Company'
        }
    },
    created() {
        this.token = this.$route.query.token;
        this.userInvitationToken = this.$route.query.ui_token;
        this.loadCompanyInvitation();
    },
    methods: {
        loadCompanyInvitation() {
            this.toggleLoading();

            CompanyInvitations
                .token({
                    token: this.token,
                    ui_token: this.userInvitationToken,
                })
                .then(response => this.setCompanyInvitationData(response))
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        setCompanyInvitationData(response) {
            this.companyInvitation = response.data;
            this.companyInfo.company_name = this.companyInvitation.invited_company.name;
            this.companyInfo.company_tid = this.companyInvitation.invited_company.tid;
            this.userInfo = response.data.invited_user || {};

            if (!this.userInfo.email)
                this.userInfo.email = this.companyInvitation.email;
        },
        submitSignup() {
            this.companyValidationErrors = {};
            this.userValidationErrors = {};
            this.toggleLoading();
            this.clearAEH();

            CompanyInvitations
                .accept(this.defineSignUpData())
                .then(() => this.goLoginPageAfterSucceed())
                .catch(response => this.handleSubmitError(response))
                .finally(() => this.toggleLoading());
        },
        defineSignUpData() {
            return {
                token: this.token,
                ui_token: this.userInvitationToken,
                user: {
                    first_name: this.userInfo.first_name,
                    last_name: this.userInfo.last_name,
                    email: this.userInfo.email,
                    phone: this.userInfo.phone,
                    profession_id: this.userInfo.profession_id,
                    password: this.userInfo.password,
                    password_confirmation: this.userInfo.password_confirmation,
                },
                company: {
                    name: this.companyInfo.company_name,
                    tid: this.companyInfo.company_tid,
                    address_street: this.companyInfo.company_address_street,
                    address_city: this.companyInfo.company_address_city,
                    address_state: this.companyInfo.company_address_state,
                    address_zip: this.companyInfo.company_address_zip,
                }
            };
        },
        handleSubmitError(response) {
            this.handleApiError(response);

            this.companyValidationErrors = this.exportRequestObjectErrors('company');
            this.userValidationErrors = this.exportRequestObjectErrors('user');

            if (Object.keys(this.userValidationErrors).length > 0) {
                this.setCurrentStep(STEP_USER_INFO);
            }
        },
        updateCompanyInfo(companyData) {
            this.companyInfo = companyData;
            this.submitSignup();
        },
        updateUserInfo(userData) {
            this.userInfo = userData;

            this.setCurrentStep(STEP_COMPANY_INFO);
        },
        goLoginPageAfterSucceed() {
            this.$router.push({
                name: 'Login',
                query: {
                    modal: 'invite-company-success',
                    message: `You've successfully created an account for ${this.companyInvitation.invited_company.name}.`
                }
            });
        },
        setCurrentStep(step) {
            this.currentStep = step;
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    }
}
</script>

<style scoped></style>
